@import 'mixings';
.chip-container{
    width: 135px;
    height: 33px;
    display: flex;
    border-radius: 50px;
    align-items: center;
    color: white;
    @include font-style('body');
    font-size: 14px;
}

.vertical-line-chip{
    border-left: 1px solid #ffffff;
    margin-right: 10px;
    height: 25px;
}

.background-green{
    background: var(--color-primary-green);
}

.background-yellow{
    background: var(--color-primary-yellow);
}

.background-red{
    background: var(--color-primary-red);
}

.icon-text{
    padding: 7px 7px 5px 10px
}
