@import 'mixings';
.btn-group{
    border: 0;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    height: var(--button-height);
    padding: 0 30px;
    cursor: pointer;
    border-radius: 50px !important;
    text-transform: none !important;
    padding: 21px 46px !important;
    font-size: 16px !important;
}

.btn-primary{
    background: var(--color-primary-blue) !important;
    color: var(--color-primary-black)  !important;
}

.btn-secondary{
    background: var(--color-primary-gray) !important;
    color: var(--color-primary-black) !important;
}

//buttons tabs
.container-buttons{
    display: flex;
    width: 210px;
}
.link{
    width: 70px;
    cursor: pointer;
}
.link-left{
    background: #e1e1e1;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 13px 12px;
}
.link p{
    @include font-style('medium')
}

.active{
    background: var(--color-primary-black) !important;
    // box-shadow: 0 0 3px 0 #000000;
    color: #ffffff;

}

.active a{
    color: #ffffff !important;
    text-decoration: none !important;
}

.link-right{
    background: #e1e1e1;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 13px 12px;
    text-align: right;
    padding-right: 15px;
}

.link a{
    @include font-style('semibold');
    text-decoration: none !important;
    color: var(--color-primary-black);
    font-size: 14px;
}