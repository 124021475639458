.container-layout{
    display: flex;
    flex-direction: column;
    margin: 20px;
    background: #fff;
    padding-top: 19px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 80%;
    margin: 30px auto;
    min-height: 500px;
}

.page{
    display: flex;
    height: 100vh;
    background: #ffffff;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.actions-buttons{
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}

.actions-buttons div{
    margin-right: 10px;
    height: 41px;
    width: 43px;
}

.right-side{
    display: flex;
}

.box-profile{
    border-bottom-right-radius: 50px;
    background-color: var(--color-primary-gray2);
    box-shadow: 1px 4px 4px 1px #cdc8c8;


}

.container-end{
    display: flex;
    align-items: flex-end;
}

.mb-20{
    margin-bottom: 20px;
}

.rotate-icon{
    transform: rotate(180deg);
}

.MuiTabPanel-root {
    padding-right: 0px !important;
}