
@import 'mixings';

.form{
    display: flex;
    flex-direction: column;
    width: 700px;
}

.form-create{
    display: flex;
    width: 100%;
}


.MuiInputLabel-root, .MuiButton-root {
    @include font-style("semibold");
    box-shadow: none !important;
}

.MuiCheckbox-root:hover{
    background-color: transparent !important;
}


.hyperlink{
    @include font-style("semibold");
    color: var(--color-primary-pink2);
    text-decoration: none !important;
    font-size: 16px;
}

.hyperlink-gray{
    @include font-style("body");
    color: var(--color-primary-gray);
    font-weight: 600;
    font-size: 14px;
}

.container-flex{
    display: flex;
    flex-direction: column;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: var(--color-primary-pink) !important;
}

.MuiCheckbox-colorPrimary {
    color: var(--color-primary-black) !important;
}

.MuiTabs-fixed .MuiTab-labelIcon{
    display: flex;
    flex-direction: row;
    text-transform: inherit;
    align-items: center;
    justify-content: center;
    color: black;
    min-height: 15px !important;
    @include font-style("medium");
}

.MuiTab-labelIcon .mt-icon-s {
    margin-right: 5px;
}

.MuiTab-labelIcon.Mui-selected{
    @include font-style("medium");
    color: var(--color-primary-black) !important;
    font-weight: 700;
}

.MuiTab-labelIcon.Mui-selected .mt-icon-s{
    color: var(--color-primary-black) !important;

}

.MuiTabs-indicator{
    background-color: var(--color-primary-blue) !important;
    height: 5px !important;
}

.form-groups{
    margin-top: 30px;
    width: 500px;
}


.subtitle-heading{
    @include font-style("semibold")
}



.error {
    padding: 15px 10px 15px 10px;
    background: var(--color-primary-pink);
    color: #ffffff;
    border: 0;
    margin-top: 15px;
    border-radius: 3px;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    max-width: 500px;
    text-align: center;
}

.user-empty{
    width: 110px;
    height: 106px;
}


.MuiTypography-h6 {
    font-weight: 600 !important;
}
.MuiOutlinedInput-root .MuiAutocomplete-input{
    padding: 0px 4px 7.5px 6px !important;
}

.MuiPaginationItem-root.Mui-selected{
    background-color: var(--color-primary-blue) !important;
    border: var(--color-primary-blue) !important;
}

.MuiPaginationItem-root{
    background-color: var(--color-primary-gray) !important;
    border: var(--color-primary-gray) !important;
}

.profilepic {
    position: relative;
    border-radius: 50%;
    width: 125px;
    height: 125px;
    overflow: hidden;
}
  
  .profilepic:hover .profilepic__content {
    opacity: .5;
    background-color: #424141;
  }
  
  .profilepic:hover .profilepic__image {
    opacity: .5;
  }
  
  .profilepic__image {
    object-fit: cover;
    opacity: 1;
    transition: opacity .2s ease-in-out;
  }
  
  .profilepic__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }
  
  .profilepic__icon {
    color: white;
    padding-bottom: 8px;
  }

  .MuiInputLabel-root{
      top: -5px !important;
  }

  .search-buttons{
    width: 100%;
    min-width: 330px;
  }

.MuiTypography-h1 {
    font-weight: 600 !important;
    font-size: 16px !important;
}

.MuiTypography-h6 {
    font-size: 10px !important;
}

.MuiTypography-subtitle{
    font-size: 12px!important;
}
.MuiFormControlLabel-root{
    margin-left: 0 !important;
    margin-right: 0 !important;
}
