:root {
    --color-primary-blue: #00ffff;
    --color-primary-blue2: #E3FDFD;
    --color-primary-black: #22252a;
    --color-primary-gray: #dddddd;
    --color-primary-gray2: #F1F1F1;;
    --color-primary-pink: #ff1f69;
    --color-primary-pink2: #FF2478;
    --color-primary-green: #22E56B;
    --color-primary-yellow: #FFD700;
    --color-primary-white: #ffffff;
    --color-primary-red: #E75757;

    --button-height: 35px;
    --input-height: 42px;

    --input-border-radious: 50px;


    --icon-size-xs: 8px;
    --icon-size-s: 16px;
    --icon-size-m: 20px;
    --icon-size-l: 24px;
    --icon-size-xl: 28px;
    --icon-size-xxl: 32px;

  }
