@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot');
  src:  url('./icomoon.eot#iefix') format('embedded-opentype'),
    url('./icomoon.ttf') format('truetype'),
    url('./icomoon.woff') format('woff'),
    url('./icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="mt-"], [class*=" mt-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mt-marker-map:before {
  content: "\e900";
}
.mt-user:before {
  content: "\e901";
}
.mt-user-check:before {
  content: "\e902";
}
.mt-user-body:before {
  content: "\e903";
}
.mt-user-selected:before {
  content: "\e904";
}
.mt-v-c-box:before {
  content: "\e905";
}
.mt-v-c-truck:before {
  content: "\e906";
}
.mt-v-c-basket:before {
  content: "\e907";
}
.mt-v-c-car-boxes:before {
  content: "\e908";
}
.mt-v-c-car-shop:before {
  content: "\e909";
}
.mt-wifi:before {
  content: "\e90a";
}
.mt-edit:before {
  content: "\e90b";
}
.mt-start:before {
  content: "\e90c";
}
.mt-focus:before {
  content: "\e90d";
}
.mt-scale-two:before {
  content: "\e90e";
}
.mt-star:before {
  content: "\e90f";
}
.mt-evaluation:before {
  content: "\e910";
}
.mt-evaluation-correct:before {
  content: "\e911";
}
.mt-evaluation-incorrect:before {
  content: "\e912";
}
.mt-date:before {
  content: "\e913";
}
.mt-filter:before {
  content: "\e914";
}
.mt-finance-bank:before {
  content: "\e915";
}
.mt-finance-bars:before {
  content: "\e916";
}
.mt-finance-ticket:before {
  content: "\e917";
}
.mt-finance-calculator:before {
  content: "\e918";
}
.mt-finance-pig:before {
  content: "\e919";
}
.mt-finance-graphic:before {
  content: "\e91a";
}
.mt-finance-currency:before {
  content: "\e91b";
}
.mt-filter-two:before {
  content: "\e91c";
}
.mt-arrow:before {
  content: "\e91d";
}
.mt-photography:before {
  content: "\e91e";
}
.mt-winner:before {
  content: "\e91f";
}
.mt-information:before {
  content: "\e920";
}
.mt-lapiz-box:before {
  content: "\e921";
}
.mt-call:before {
  content: "\e922";
}
.mt-place-warehouse:before {
  content: "\e923";
}
.mt-place-flag:before {
  content: "\e924";
}
.mt-place-house:before {
  content: "\e925";
}
.mt-place-office:before {
  content: "\e926";
}
.mt-place-store:before {
  content: "\e927";
}
.mt-search:before {
  content: "\e928";
}
.mt-maint-settings:before {
  content: "\e929";
}
.mt-maint-brush:before {
  content: "\e92a";
}
.mt-maint-stream:before {
  content: "\e92b";
}
.mt-maint-tools:before {
  content: "\e92c";
}
.mt-maint-key:before {
  content: "\e92d";
}
.mt-maint-hammer:before {
  content: "\e92e";
}
.mt-maint-saw:before {
  content: "\e92f";
}
.mt-maint-employee:before {
  content: "\e930";
}
.mt-maint-pipeline:before {
  content: "\e931";
}
.mt-maint-pipeline-cao:before {
  content: "\e932";
}
.mt-plus:before {
  content: "\e933";
}
.mt-mech-van:before {
  content: "\e934";
}
.mt-mech-car:before {
  content: "\e935";
}
.mt-mech-indicador:before {
  content: "\e936";
}
.mt-mech-wrench:before {
  content: "\e937";
}
.mt-mech-nut:before {
  content: "\e938";
}
.mt-minus:before {
  content: "\e939";
}
.mt-sent:before {
  content: "\e93a";
}
.mt-menu:before {
  content: "\e93b";
}
.mt-move:before {
  content: "\e93c";
}
.mt-others-bell:before {
  content: "\e93d";
}
.mt-others-sent:before {
  content: "\e93e";
}
.mt-others-idea:before {
  content: "\e93f";
}
.mt-others-id:before {
  content: "\e940";
}
.mt-others-medal:before {
  content: "\e941";
}
.mt-others-trophy:before {
  content: "\e942";
}
.mt-pdf:before {
  content: "\e943";
}
.mt-Recurso-131:before {
  content: "\e944";
}
.mt-Recurso-139:before {
  content: "\e945";
}
.mt-answer-correct:before {
  content: "\e946";
}
.mt-health-adn:before {
  content: "\e947";
}
.mt-health-heart:before {
  content: "\e948";
}
.mt-health-cross:before {
  content: "\e949";
}
.mt-health-bar:before {
  content: "\e94a";
}
.mt-health-rate:before {
  content: "\e94b";
}
.mt-health-thermometer:before {
  content: "\e94c";
}
.mt-sec-padlock:before {
  content: "\e94d";
}
.mt-sec-check-lock:before {
  content: "\e94e";
}
.mt-sec-protection:before {
  content: "\e94f";
}
.mt-sol-check:before {
  content: "\e950";
}
.mt-super-team:before {
  content: "\e951";
}
.mt-super-evaluation:before {
  content: "\e952";
}
.mt-super-eye:before {
  content: "\e953";
}
.mt-super-clock:before {
  content: "\e954";
}
.mt-super-clock-two:before {
  content: "\e955";
}
.mt-super-answers:before {
  content: "\e956";
}
.mt-super-supervisor:before {
  content: "\e957";
}
.mt-task-quantity:before {
  content: "\e958";
}
.mt-task-checkbox:before {
  content: "\e959";
}
.mt-task-scale:before {
  content: "\e95a";
}
.mt-task-date:before {
  content: "\e95b";
}
.mt-task-firm:before {
  content: "\e95c";
}
.mt-task-picture:before {
  content: "\e95d";
}
.mt-task-price:before {
  content: "\e95e";
}
.mt-task-price-two:before {
  content: "\e95f";
}
.mt-task-radio-button:before {
  content: "\e960";
}
.mt-task-answer:before {
  content: "\e961";
}
.mt-task-yes-no:before {
  content: "\e962";
}
.mt-tec-circuits:before {
  content: "\e963";
}
.mt-tec-web:before {
  content: "\e964";
}
.mt-marker:before {
  content: "\e965";
}
.mt-marker-specify:before {
  content: "\e966";
}
.mt-duplicate:before {
  content: "\e967";
}
.mt-stop:before {
  content: "\e968";
}
.mt-description:before {
  content: "\e969";
}
.mt-download:before {
  content: "\e96a";
}
.mt-conditional:before {
  content: "\e96b";
}
.mt-check-box:before {
  content: "\e96c";
}
.mt-check:before {
  content: "\e96d";
}
.mt-close:before {
  content: "\e96e";
}
.mt-box-user:before {
  content: "\e96f";
}
.mt-trash:before {
  content: "\e970";
}
.mt-alert-two:before {
  content: "\e971";
}
.mt-alert:before {
  content: "\e972";
}
.mt-update:before {
  content: "\e973";
}
