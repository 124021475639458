.mt-icon-xs{
    font-size: var(--icon-size-xs);
}

.mt-icon-s{
    font-size: var(--icon-size-s);
}

.mt-icon-m{
    font-size: var(--icon-size-m);
}

.mt-icon-l{
    font-size: var(--icon-size-l);
}

.mt-icon-xl{
    font-size: var(--icon-size-xl);
}

.mt-icon-xxl{
    font-size: var(--icon-size-xxl);
}

.mt-color-gray{
    color: var(--color-primary-gray)
}

.mt-color-blue{
    color: var(--color-primary-blue)
}

.mt-color-black{
    color: var(--color-primary-black)
}

.mt-color-white{
    color: var(--color-primary-white)
}

.mt-color-pink{
    color: var(--color-primary-pink)
}

.mt-color-yellow{
    color: var(--color-primary-yellow)
}

.mt-color-green{
    color: var(--color-primary-green)
}


.background{
    width: 14px;
    height: 14px;
    width: 35px;
    height: 35px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.color-gray{
    background: var(--color-primary-gray);
}

.color-green{
    background: var(--color-primary-green);
}

.color-pink{
    background: var(--color-primary-pink);
}

.color-red{
    background: var(--color-primary-red);
}

.color-yellow{
    background: var(--color-primary-yellow);
}

.icon-container{
    display: flex;
    margin-bottom: 2px !important;
}