.fix-width .MuiTooltip-tooltip {
    max-width: 240px !important;
    width: 240px !important;
}

.MuiTooltip-tooltip{
    border-radius: 20px !important;
    white-space: pre-wrap;
    background-color: #22252A !important;
    font-size: 16px !important;
    @include font-style('body');
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.57);
    margin-left: -8px !important;
    margin-top: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;

}

.MuiTooltip-arrow::before{
    background-color: #22252a !important;
}

