@import 'mixings';

.table-custom{
    width: 100% !important;
}
.MuiTable-root thead th{
    @include font-style("medium");
    font-size: 16px;
}
.MuiTable-root tbody th{
    @include font-style("body");
    font-size: 12px !important;
}

.MuiTableContainer-root{
    box-shadow: none !important;
    border-radius: 0px !important;
}