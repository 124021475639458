.menu-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-weight: 600;
    font-size: 14px;
}

.menu-links li {
    float: left;
}

.menu-links li.active a {
    color: var(--color-primary-blue) !important;
}
  
.menu-links li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}

