.circle{
    border-radius: 50%;
    background-color: white;
    
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 15px 9px 0 rgba(0,0,0,0.6);


}

.circle-small{
    height: 135px;
    width: 135px;
}

.circle-big{
    height: 175px;
    width: 175px;
}


.circle img{
    filter: invert(48%) sepia(11%) saturate(360%) hue-rotate(
167deg) brightness(91%) contrast(88%);
    width: 70%;

}

.title{
    margin-top: 26px;
    color: white;
    font-weight: 600;
    text-align: center;
}


.inputRounded .MuiOutlinedInput-root  {
    border-radius: 50px !important;
    height: 40px;
}

.label{
    display: flex !important;
    margin-bottom: 17px;
    font-size: 16px !important;
    color: #000 !important;
}
.subtitle{
    margin-bottom: 98px;
    margin-top: 10px;
}

.div-container {
    display: flex !important;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}
.div-one {
    display: flex;
    flex: 50% !important;
    align-items: flex-start;
    background-color: #22252a;
    flex-direction: column;
}
.div-two {
    display: flex;
    flex: 50% !important;
    background-color: #ffffff;;
    align-items: center;
    justify-content: center;
}
.img{
    width: 30px;
    height: 30px;
    margin-top: 30px;

    
}

.img-mt{
    width: 170px;
    margin-top: 40px;
    margin-left: 60px;
}

.description{
    color: #ffffff;
}

.mr-20{
    margin-right: 50px;
}

.form-login{
    margin-left: -25px;
    display: flex;
    flex-direction: column;
    min-width: 500px;
    @media screen and (max-width: 600px) {
        width: 300px;
        min-width: auto;


    }
}

.box-icons{
    display: flex;
    width:100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 200px;
    @media screen and (max-width: 600px) {
        margin-top: 100px;
        flex-direction: column;

    }
}

.icon-box{
    @media screen and (max-width: 600px) {
        margin-right: 0px !important;
        margin-bottom: 15px;
    }
}

.description, .welcome-message{
    @media screen and (max-width: 600px) {
        padding: 15px;
        text-align: center;
    }
}
.subtitle{
    @media screen and (max-width: 600px) {
        margin-bottom: 25px;
    }
}