.inputRounded .MuiOutlinedInput-root  {
    border-radius: 50px !important;
    height: var(--input-height);
    background: var(--color-primary-gray2) !important;
}


.selectRounded .MuiOutlinedInput-root  {
    background: var(--color-primary-blue2) !important;
}

.inputRoundedSearch .MuiOutlinedInput-root  {
    border-radius: 50px !important;
    height: var(--input-height);
    background: var(--color-primary-gray2) !important;
}

.inputRoundedSearch .MuiOutlinedInput-root input{
    border-left: 1px solid gray;
    padding: 2px 20px 0 19px;
}

.inputRounded{
    width: 100% !important;
}

.right-side-search{
    margin-top: 50px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}

.MuiInputAdornment-root .icon-container{
    padding-right: 10px;
}



input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px var(--color-primary-gray2) inset !important;
    height: 9px;
}

input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0px 1000px var(--color-primary-gray2) inset !important;
}


.inputRoundedSearch ::-webkit-input-placeholder { 
    color: #000 !important;
}
.MuiOutlinedInput-notchedOutline{
    border: none !important;
}

.MuiInputBase-multiline{
    background: var(--color-primary-gray2);
    border-radius: 10px !important;
}