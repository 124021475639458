@import 'mixings';
@import 'responsive';
.header-background .MuiPaper-root{
    background-color: var(--color-primary-black);
}

.logo{
    width: 100px;
    cursor: pointer;
}
.logo-icon{
    width: 25px;
    cursor: pointer;
}
.MuiTypography-root{
    @include font-style('body');
    font-size: 14px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.vertical-line{
    border-left: 1px solid gray;
    margin-right: 15px;
    margin-left: 5px;
}

.horizontal-line{
    display: flex;
    width: 100%;
    border-bottom: 1px solid gray;
}

.menu-header .MuiMenu-list {
    background-color: var(--color-primary-black);
    color: var(--color-primary-gray);
    width: 230px;
}

.MuiPaper-root .MuiPaper-elevation{
    top: 70px !important;
}

.header-pop{
    border-bottom: 1px solid #100f0f;
    -webkit-box-shadow:15px 3px 8px #0e0d0e;
    box-shadow: 15px 3px 8px #0e0d0e;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    margin-bottom: 20px;
}

.title-menu{
    @include font-style('body');
    font-size: 14px;
    font-weight: 600;
}
.subtitle-menu{
    @include font-style('body');
    font-size: 12px;
}
.container-edit-profile{
    margin-left: 10px;
    margin-bottom: 10px;
}

.MuiToolbar-regular{
   
    @media #{$desktop-bp} {
        padding-left: 150px !important;
        padding-right: 150px !important;
    }
}


.modal-header{
    background-color: var(--color-primary-pink);
    color:white;
}

.modal-header h2{
    font-size: 16px !important;
}

.MuiDialogContent-root {
    font-size: 14px !important;
}